<template>
  <div class="workout-item">
    <router-link :to="{ name: 'workout-detail', params:{id:$root.getLink(authorWorkoutItem)}}" class="workout-item-link">
      <div class="workout-item-image">
        <img :src="authorWorkoutItem.media.general[0].image" alt="" v-if="authorWorkoutItem.media &&authorWorkoutItem.media.general.length > 0">
      </div>
      <div class="workout-item-top">
        <div class="d-flex justify-content-between align-items-center">
          <div class="workout-item-flag-wrapper">
            <div class="workout-item-flag d-flex justify-content-center align-items-center" v-if="authorWorkoutItem.premium > 0">
              <div class="workout-item-flag-icon d-flex justify-content-center align-items-center d-lg-inline-block">
                <lock2/>
              </div>
              <p class="d-none d-lg-block">PREMIUM</p>
            </div>
          </div>
        </div>
      </div>
      <div class="workout-item-content">
        <p class="workout-item-title">{{ authorWorkoutItem.title }}</p>
        <div class="workout-item-tags">
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!authorWorkoutItem.duration">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <clock/>
            </div>
            <p>{{ authorWorkoutItem.duration }}</p>
          </div>
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!authorWorkoutItem.itensity">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <level/>
            </div>
            <p>{{ authorWorkoutItem.itensity }}</p>
          </div>
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!authorWorkoutItem.level">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <fire/>
            </div>
            <p>{{ authorWorkoutItem.level }}</p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import bookmark from "@/components/svg/bookmark.vue";
import lock2 from "@/components/svg/lock2.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";

export default {
  name: "author-item",
  props: {
    authorWorkoutItem: {
      required: true,
    },
  },
  components : {
    bookmark,
    lock2,
    clock,
    level,
    fire
  }
}
</script>