import { render, staticRenderFns } from "./author-slider.vue?vue&type=template&id=89f760b4&"
import script from "./author-slider.vue?vue&type=script&lang=js&"
export * from "./author-slider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports