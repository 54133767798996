<template>
  <div class="author-slider-wrapper section-padding">
    <div class="container">
      <div class="workouts-slider-holder">
        <div class="workouts-slider-heading d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start align-items-center">
            <div class="author-image" v-if="!!authorItem.photo">
              <img :src="authorItem.photo" alt="">
            </div>
            <p class="section-title">{{ authorItem.title }}</p>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <router-link :to="{ name: 'author-detail', params:{id:$root.getLink(authorItem)} }" class="heading-link">Ver perfil</router-link>
            <div class="workouts-slider-arrows d-none d-xl-flex align-items-center justify-content-center">
              <button type="button" class="workouts-slider-arrow workouts-slider-prev" @click="prevSlide">
                <chevronLeft/>
              </button>
              <button type="button" class="workouts-slider-arrow workouts-slider-next" @click="nextSlide">
                <chevronLeft/>
              </button>
            </div>
          </div>
        </div>
        <Slick ref="slick" :options="slickOptions" class="workouts-slider">
          <div class="workouts-slider-item" v-for="(item,index) in authorItem.products" :key="index" >
            <authorItem :authorWorkoutItem="item"/>
          </div>
        </Slick>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import authorItem from "@/components/author/author-item.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";

export default {
  name: "author-slider",
  props: {
    authorItem: {
      required: true,
    },
  },
  components : {
    Slick,
    authorItem,
    chevronLeft
  },
  data: function () {
    return {
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        variableWidth: true
      }
    }
  },
  methods: {
    prevSlide: function() {
      this.$refs.slick.prev();
    },
    nextSlide: function() {
      this.$refs.slick.next();
    },
  },
}
</script>