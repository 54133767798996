<template>
  <div class="dashboard workout-by-page author-list-page">
    <div class="workout-by-list-heading-wrapper">
      <div class="container">
        <div class="workout-by-list-heading d-flex justify-content-between align-items-center">
          <div class="d-none d-xl-flex justify-content-start align-items-center">
            <!--button type="button" class="heading-back">
              <chevronLeft/>
            </button-->
            <p class="section-title uppercase">TREINO DE AUTOR</p>
          </div>
<!--          <div class="workout-by-list-options d-flex align-items-center justify-content-xl-end">-->
<!--            <div class="order-select-wrapper">-->
<!--              <label class="d-flex justify-content-center align-items-center">-->
<!--                <div class="relative">-->
<!--                  <select>-->
<!--                    <option>Ordenar</option>-->
<!--                    <option>1</option>-->
<!--                    <option>2</option>-->
<!--                    <option>3</option>-->
<!--                  </select>-->
<!--                  <div class="order-select-icon">-->
<!--                    <orderIcon/>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
       </div>
      </div>
    </div>
    <div class="author-list">
      <authorSlider v-for="(item,index) in AuthorSliderItems" :key="index" :authorItem="item" />
    </div>
  </div>
</template>

<script>
//Components
import authorSlider from "@/components/author/author-slider.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import filtersIcon from "@/components/svg/filters.vue";
import orderIcon from "@/components/svg/order-icon.vue";
import filters from "@/components/filters.vue";
import {EventBus} from "@/event-bus";
import axios from "axios";

export default {
  name: "author-list",
  metaInfo () {
    return {
      title: this.$root.translations.treino_autor,
    }
  },
  components : {
    authorSlider,
    chevronLeft,
    filtersIcon,
    orderIcon,
    filters
  },
  data: function () {
    return {
      lang: this.$root.siteLang,
      AuthorSliderItems: [],
    }
  },
  methods : {
    toggleFilters() {
      let element = document.getElementsByClassName("filters-wrapper")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    }
  },
  mounted() {
    EventBus.$on('bus-toggleFilters', () => {
      let element = document.getElementsByClassName("filters-wrapper")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    });
  },
  created() {
    this.$root.pageTitle = this.$root.translations.treino_autor;

    axios
        .get(process.env.VUE_APP_API_URL + 'authors?with_products=1&lang=' + this.lang)
        .then((response) => {
          this.AuthorSliderItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}
</script>